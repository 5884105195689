$-zf-breakpoints-keys: map-to-list($breakpoints, 'keys');

/// Generates a media query string matching the input value. Refer to the documentation for the `breakpoint()` mixin to see what the possible inputs are.
///
/// @param {Keyword|Number} $val [small] - Breakpoint name, or px, rem, or em value to process.
@function breakpoint($val: $-zf-zero-breakpoint) {
  // Size or keyword
  $bp: nth($val, 1);
  // Value for max-width media queries
  $bp-max: 0;
  // Direction of media query (up, down, or only)
  $dir: if(length($val) > 1, nth($val, 2), up);
  // Eventual output
  $str: '';
  // Is it a named media query?
  $named: false;

  // Orientation media queries have a unique syntax
  @if $bp == 'landscape' or $bp == 'portrait' {
    @return '(orientation: #{$bp})';
  }
  @else if $bp == 'retina' {
    @return '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
  }

  // Try to pull a named breakpoint out of the $breakpoints map
  @if type-of($bp) == 'string' {
    @if map-has-key($breakpoints, $bp) {
      @if $dir == 'only' or $dir == 'down' {
        $bp-max: -zf-map-next($breakpoints, $bp);
      }

      $bp: map-get($breakpoints, $bp);
      $named: true;
    }
    @else {
      $bp: 0;
      @warn 'breakpoint(): "#{$val}" is not defined in your $breakpoints setting.';
    }
  }

  // Convert any pixel, rem, or unitless value to em
  $bp: -zf-bp-to-em($bp);
  @if $bp-max {
    $bp-max: -zf-bp-to-em($bp-max) - (1/16);
  }

  // Conditions to skip media query creation
  // - It's a named breakpoint that resolved to "0 down" or "0 up"
  // - It's a numeric breakpoint that resolved to "0 " + anything
  @if $bp > 0em or $dir == 'only' or $dir == 'down' {
    // `only` ranges use the format `(min-width: n) and (max-width: n)`
    @if $dir == 'only' {
      // Only named media queries can have an "only" range
      @if $named == true {
        // Only use "min-width" if the floor is greater than 0
        @if $bp > 0em {
          $str: $str + '(min-width: #{$bp})';

          // Only add "and" to the media query if there's a ceiling
          @if $bp-max != null {
            $str: $str + ' and ';
          }
        }

        // Only use "max-width" if there's a ceiling
        @if $bp-max != null {
          $str: $str + '(max-width: #{$bp-max})';
        }
      }
      @else {
        @warn 'breakpoint(): Only named media queries can have an `only` range.';
      }
    }

      // `down` ranges use the format `(max-width: n)`
    @else if $dir == 'down' {
      $max: if($named, $bp-max, $bp);

      // Skip media query creation if input value is exactly "0 down",
      // unless the function was called as "small down", in which case it's just "small only"
      @if $named or $bp > 0em {
        @if $max != null {
          $str: $str + '(max-width: #{$max})';
        }
      }
    }

      // `up` ranges use the format `(min-width: n)`
    @else if $bp > 0em {
      $str: $str + '(min-width: #{$bp})';
    }
  }

  @return $str;
}

@mixin breakpoint($value) {
  $str: breakpoint($value);
  $bp: index($-zf-breakpoints-keys, $value);
  $pbp: index($-zf-breakpoints-keys, $print-breakpoint);

  // If $str is still an empty string, no media query is needed
  @if $str == '' {
    @content;
  }

    // Otherwise, wrap the content in a media query
  @else {
    // For named breakpoints less than or equal to $print-breakpoint, add print to the media types
    @if $bp != null and $bp <= $pbp {
      @media print, screen and #{$str} {
        @content;
      }
    }
    @else {
      @media screen and #{$str} {
        @content;
      }
    }
  }
}
