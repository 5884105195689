.main {

    .full-height {
        height: 900px;
        max-height: calc(100vh - 90px);

        /*@include breakpoint(medium) {
          height: 100vh;
        }*/
    }

    .wrapper {
        display: flex;
        flex-flow: column;
        @include breakpoint(medium) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
        }

        .text_title,
        .breadcrumb,
        .page--title {
            margin-top: 0;

            @include breakpoint(medium) {
                margin-top: 130px;
            }

            @include breakpoint(large) {
                margin-top: 160px;
            }
        }

        .text_title {
            text-align: center;
        }

        .breadcrumb ul li {
            display: inline-block;

            &::after {
                content: '/';
                color: #858585;
            }

            &:last-child::after {
                content: none;
            }
        }

        & > div.text_intro {
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex: 1;

            @include breakpoint(medium) {
                margin-top: 120px;
            }

            & > div {
                padding: 0 16px;
                @include breakpoint(large) {
                    padding: 0 47px;
                }
            }

            .arrow-more {
                display: block;
                width: 100%;
                cursor: pointer;
                height: 31px;
                background: transparent url('../images/arrow.svg') bottom center no-repeat;

                bottom: 20px;
                position: absolute;
                margin-left: -20px;

                animation: bounce 0.5s;
                animation-direction: alternate;
                animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
                animation-iteration-count: infinite;

                @include breakpoint(medium) {
                    height: 51px;
                }

                @include breakpoint(large) {
                    margin-left: -47px;
                    position: absolute;
                }

                @media screen and (max-height: 560px) and (max-width: 640px) {
                    right: 5px;
                    width: 31px;
                }

            }
        }

        & > div.image_intro {
            overflow: hidden;
            height: 50%;

            @include breakpoint(medium) {
                height: 100%;
                max-height: none;
                order: 2;
                flex: 1;
            }

            & > div {
                height: 100%;
            }

            span {
                height: 100%;

                img {
                    object-fit: cover;
                }
            }
        }

        .banner {
            padding: 40px 0;
            @include breakpoint(large) {
                padding: 120px 0;
            }

        }
        .content {
            max-width: 784px;
            margin: 40px 0 10px 0;
            @include breakpoint(large) {
                margin: 50px 0 20px 0;
            }
        }

        .team {
            padding-top: 112px;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            & > div {
                width: 234px;
                margin: 0 58px 112px 58px;
            }
            .image {
                margin-bottom: 15px;
                @include breakpoint(large) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.split {
    @include breakpoint(large) {
        display: flex;
        flex-flow: row wrap;
    }

    > * {
        @include breakpoint(large) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

.tns-outer, .wrapper > .slider {
    margin: 30px 0 75px 0;
    width: 100%;
    position: relative;

    @include breakpoint(large) {
        margin: 70px 0 120px 0;
    }

    .tns-inner {
        width: 100%;
        margin: 0 auto;

        @include breakpoint(large) {
            width: percentage(10/12);
        }
    }

    .tns-item {
        text-align: center;

        &.landscape img {
            width: 100%;
        }
    }

    .caption {
        text-align: left;

        p {
            margin: 0 16px;

            @include breakpoint(large) {
                margin: 16px 0 0 0;
            }
        }
    }

}

.wrapper > .slider > div {
    width: 100%;
    margin: 0 auto;

    @include breakpoint(large) {
        width: percentage(10/12);
    }

}

.tns-controls {
    width: 90%;

    @include breakpoint(large) {
        width: 100%;
    }

    button {
        position: absolute;
        left: 15px;
        z-index: 1;
        appearance: none;
        background-image: url(../images/arrow--left--black.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        width: 50px;
        height: 22px;
        overflow: hidden;
        text-indent: 100%;
        padding: 0;
        transform: translateY(-50%);
        outline: none;
        top: 50%;

        @include breakpoint(large) {
            top: 50%;
            bottom: auto;
        }

        &:nth-child(2) {
            left: auto;
            right: 13px;
            background-image: url(../images/arrow--right--black.svg);
        }
    }
}

.pager {
    position: relative;
    width: 100%;
    height: 28px;
    margin-bottom: 30px;
    .prev {
        position: absolute;
        left: 0;
    }

    .next {
        position: absolute;
        right: 0;
        top: 30px;

        @include breakpoint(large) {
            top: auto;
        }
    }
}

.contact-header .wrapper {
    text-align: center;
}

.projectlist {
    margin-top: 35px;
    @include breakpoint(large) {
        margin-top: 80px;
    }
    .title {
        margin: 15px 0 30px 0;
    }

    @include breakpoint(large) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .item {
            flex-basis: 49%;
            width: 50%;
            padding-top: 16px;
            padding-left: 0;
            padding-right: 8px;

            & > div {
                position: relative;
                .title {
                    background-color: $grey;
                    margin: 0;
                    display: none;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    display: none;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    h2 {
                        color: $white;
                    }
                }
                &:hover .title {
                    display: flex;
                }
            }
        }
        .item:nth-child(2n) {
            padding-left: 8px;
            padding-right: 0;
        }
        .item:nth-child(-n+2) {
            padding-top: 0;
        }
    }
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 20px, 0);
    }
}

.address {
    width: 100%;
}

#map {
    height: 200px;
    width: 100%;

    @include breakpoint(large) {
        height: 400px;
    }
}