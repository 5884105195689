html {
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  @include medium;
  scroll-behavior: smooth;
}

*, ::before, ::after {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

ul li {
  list-style: none;
}

body {
  margin: 0;
  min-height: 100vh;
  overflow-y: scroll;
}

.wrapper {
  max-width: rem-calc($max-width);
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.row {
  width: 100%;
  position: relative;
  display: block;
  padding: 0 16px;

  @include breakpoint(large) {
    padding: 0 47px;
  }

  & > div {
    width: 100%;
  }

}

.flex-center {
  display: flex;
  justify-content: center;
  @include breakpoint(medium) {
    width: 100%
  }
}

input[type=checkbox] {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0;
}

.grey {
  background-color: $grey;
}

.black {
  background-color: $black;
}