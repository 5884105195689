@font-face {
  font-family: 'metropolisblack';
  src: url('../webfonts/metropolis-black-webfont.woff2') format('woff2'),
  url('../webfonts/metropolis-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'metropolisblack_italic';
  src: url('../webfonts/metropolis-blackitalic-webfont.woff2') format('woff2'),
  url('../webfonts/metropolis-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'metropolismedium';
  src: url('../webfonts/metropolis-medium-webfont.woff2') format('woff2'),
  url('../webfonts/metropolis-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'metropolisregular_italic';
  src: url('../webfonts/metropolis-regularitalic-webfont.woff2') format('woff2'),
  url('../webfonts/metropolis-regularitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin regularitalic {
  font-family: 'metropolisregular_italic';
}

@mixin medium {
  font-family: 'metropolismedium';
  font-weight: normal;
}

@mixin blackitalic {
  font-family: 'metropolisblack_italic';
}

@mixin black {
  font-family: 'metropolisblack';
}

@mixin h1($color: $black) {
  @include blackitalic();
  text-transform: uppercase;
  font-size: rem-calc(30);
  line-height: rem-calc(46);
  margin: 0;

  @include breakpoint(large) {
    font-size: rem-calc(45);
    line-height: rem-calc(61);
  }

  @include breakpoint(xlarge) {
    font-size: rem-calc(55);
    line-height: rem-calc(71);
  }
}

@mixin h2($color: $black) {
  @include blackitalic();
  font-size: rem-calc(30);
  line-height: rem-calc(40);
  color: $color;
  text-transform: uppercase;
  margin: 0;

  @include breakpoint(large) {
    font-size: rem-calc(40);
    line-height: rem-calc(48);
  }
}

@mixin h3($color: $black) {
  @include blackitalic();
  font-size: rem-calc(20);
  line-height: rem-calc(28);
  color: $color;
  text-transform: uppercase;
  margin: 0;

  @include breakpoint(large) {
    font-size: rem-calc(24);
    line-height: rem-calc(32);
  }
}

@mixin p($color: $black) {
  @include medium;
  font-size: rem-calc(18);
  line-height: rem-calc(28);
  color: $color;
  margin: 0;

  strong {
    @include black;
    font-weight: normal;
  }
}

@mixin more($color: $black) {
  @include blackitalic();
  font-size: rem-calc(18);
  line-height: rem-calc(28);
  color: $color;
  text-transform: uppercase;
}

@mixin text-intro($color: $black) {
  @include black();
  font-size: rem-calc(18);
  line-height: rem-calc(24);
  color: $color;
  @include breakpoint(large) {
    font-size: rem-calc(24);
    line-height: rem-calc(36);
  }
}

@mixin banner($color: $black) {
  @include blackitalic();
  font-size: rem-calc(20);
  line-height: rem-calc(28);
  color: $color;
  text-transform: uppercase;

  @include breakpoint(large) {
    font-size: rem-calc(40);
    line-height: rem-calc(48);
  }
}

@mixin nav {
  @include blackitalic();
  font-size: rem-calc(30);
  line-height: rem-calc(65);
  text-transform: uppercase;

  .menu {

    li > ul li {
      @include regularitalic();
      font-size: rem-calc(23);
      line-height: rem-calc(36);
    }
  }
}

@mixin breadcrumb {
  @include blackitalic();
  font-size: rem-calc(16);
  line-height: rem-calc(24);
  text-transform: uppercase;

  @include breakpoint(large) {
    font-size: rem-calc(20);
    line-height: rem-calc(28);
  }
}

nav {
  @include nav;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

p {
  @include p;
  margin: 20px 0;

  @include breakpoint(xlarge) {
    margin: 30px 0;
  }
}

a {
  text-decoration: none;
  color: $black;
}

.more {
  @include more;
}

.grey,
.black {
  h2, h3, p, .more, a {
    color: $white;
  }
}

.text_intro p {
  @include text-intro;
}

.banner {
  @include banner($white);
  text-align: center;
}

.team {
  text-align: center;
  .name {
    @include text-intro($white);
    @include blackitalic();
  }
  .section {
    @include p($white);
  }

}

.pager li {
  @include more;
  text-decoration: none;
  a {
    color: $black;
    text-decoration: none
  }
}

.projectlist .title h2 {
  @include banner($black);
}

.breadcrumb {
  @include breadcrumb;

  a {
    color: $grey;
  }
}

.grey .breadcrumb a,
.template--article .breadcrumb a {
  color: $white;
}

