div.wrapper-offcanvas {
  position: relative;
  padding:0;
}

#offcanvas-open {
  z-index: 999;
  right: 16px;
  top: 20px;
  position: absolute;
  width: 49px;
  height: 49px;
  background-color: black;
  display: block;
  cursor: pointer;

  @include breakpoint(large) {
     right: 47px;
     top: 47px;
  }

  span {
    display: block;
    height: 3px;
    width: 24px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 23px;
    left: 13px;
    -webkit-transition: all .25s ease;
    transition: all .25s ease
  }

  span:after,
  span:before {
    content: "";
    display: block;
    background-color: #fff;
    width: 100%;
    height: 3px;
    position: absolute;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
  }

  span:before {
    top: -8px;
  }

  span:after {
    bottom: -8px;
  }
}

.offcanvas {
  display: none;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 998;

  @include breakpoint(medium) {
    width:50%;
  }

  nav {
    position: absolute;
    width: 100%;
    padding: 69px 16px 16px 16px;

    @include breakpoint(large) {
      padding: 96px 47px 47px 47px;
    }

    .menu {
      overflow: auto;
      height: 100vh;
      height: calc(100vh - 69px);

      @include breakpoint(large) {
        height: calc(100vh - 96px);
      }

      li {
        list-style: none;
        text-align: center;
        a {
          color: $white;
          text-decoration: none;
        }
        & > ul {
          margin-top: 3px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

.template--article {
  #offcanvas-open {
    background-color: $white;

    span, span::before, span::after {
      background-color: $black;
    }
  }
}

body #menu-switch:checked {
  & ~ .container {
    overflow: hidden;
    height: 100vh;
  }

  & ~ .container header div.offcanvas {
    display: block;
  }

  & ~ .container header #offcanvas-open {
    background-color: transparent;
  }

  & ~ .container header #offcanvas-open span {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  & ~ .container header #offcanvas-open span:after {
    opacity: 0;
  }

  & ~ .container header #offcanvas-open span:before {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 0;
  }
}

