$max-width:         1280;
$global-font-size:  16;

// colors
$white: #fff;
$black: #000;
$grey:  #858585;

// breakpoints
$print-breakpoint: large;
$breakpoints: (
        small: 0,
        medium: 640px,
        large: 768px,
        xlarge: 1024px,
        xxlarge: 1440px,
);
$breakpoint-classes: (small medium large xlarge xxlarge);

@import 'tiny-slider';

@import 'rem-calc',
        'breakpoints',
        'fonts',
        'defaults',
        'header',
        'main',
        'home',
        'article',
        'footer',
        'menu';