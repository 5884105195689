.template--article,
.template--news {
  background-color: $grey;

  .breadcrumb li::after {
    color: $white !important;
  }

  h1 {
    color: $white;
    text-align: center;
  }

  p {
    color: $white;
  }
}

.template--article {
  h1 {
    margin-bottom: 36px;

    @include breakpoint(large) {
      margin-bottom: 62px;
    }
  }
}

.template--news {
  .list .row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 80px;

    .content {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0;

      @include breakpoint(large) {
        flex: 0 0 percentage(5/12);
        max-width: percentage(5/12);
      }
    }

    .image {
      flex: 0 0 100%;
      max-width: 100%;

      @include breakpoint(large) {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }
  }

  h2, p, .more {
    color: $white;
  }

  .page--title {
    margin-bottom: 36px;

    @include breakpoint(large) {
      margin-bottom: 82px;
    }
  }
}