footer {
  .row {
    margin-top: 75px;
    @include breakpoint(large) {
      margin-top: 112px;
    }

    .content {
      p {
        margin-top: 0;
      }

      padding-top: 48px;
      padding-bottom: 32px;

      @include breakpoint(large) {
        padding-top: 80px;
        padding-bottom: 48px;
      }

      @include breakpoint(large) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }

    }

    .logo {
      @include breakpoint(large) {
        max-width: percentage(5/12);
      }
    }

    .address {
      text-align: center;
      padding-top: 40px;

      @include breakpoint(large) {
        padding-top: 0;
        text-align: left;
        max-width: percentage(4/12);
      }

      h2, p {
        font-size: rem-calc(14);
        line-height: rem-calc(20);
      }

      h2 {
        @include black;
        font-weight: normal;
        text-transform: none;
      }

      p {
        @include medium;
      }
    }

    .top {
      padding-top: 40px;

      @include breakpoint(large) {
        padding-top: 0;
        width: 22px;
      }

      a {
        display: block;
        margin: 0 auto;
        width: 22px;
        height: 50px;
        background-image: url(../images/arrow.svg);
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(180deg);
      }
    }
  }
  .grey .top a {
    background-image: url(../images/arrow-white.svg);
  }
}

