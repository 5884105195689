header {
  .wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .logo {
      z-index: 997;
      margin:20px 0 20px 20px;
      max-width: 484px;
      height: 50px;
      line-height: 50px;
      font-size: 1px;
      width: 59%;
      margin: 20px 0 20px 20px;
      @include breakpoint(medium) {
        width: 39%;
        position: absolute;
      }
      @include breakpoint(large) {
        margin: 47px 0 47px 47px;
      }
      img {
        vertical-align: middle;
        max-height: 50px;
        width: auto;
        @include breakpoint(medium) {
          max-height: none;
          width: 100%;
          max-width: 484px;
        }
      }
    }
  }
}