.template--home {
  .news {
    padding-top: 27px;
    padding-bottom: 32px;

    @include breakpoint(xlarge) {
      padding-top: 42px;
      padding-bottom: 50px;
    }

    h3 {
      padding-bottom: 15px;

      @include breakpoint(xlarge) {
        padding-bottom: 48px;
      }
    }

    aside {
      padding-left: 8px;
    }
  }

  .customteaser {

    article {
      margin: 30px 0;
      width: 100%;

      @include breakpoint(large) {
        margin: 0;
      }
    }

    h3 {
      padding-top: 28px;

      @include breakpoint(xlarge) {
        padding-top: 40px;
      }
    }

    h2 {
      padding-top: 15px;
      padding-bottom: 3px;

      @include breakpoint(xlarge) {
        padding-top: 47px;
        padding-bottom: 0;
      }
    }

    .more {
      display: block;
      padding-top: 8px;
      padding-bottom: 10px;

      @include breakpoint(xlarge) {
        padding-top: 0;
      }
    }

  }

  .services {

    h3 {
      padding-top: 27px;

      @include breakpoint(xlarge) {
        padding-top: 42px;
      }
    }

    .tns-controls {
      button {
        background-image: url(../images/arrow--left--white.svg);
        left: 0;
        bottom: 16px;

        @include breakpoint(xlarge) {
          top: 50%;
        }


        &:nth-child(2) {
          background-image: url(../images/arrow--right--white.svg);
          right: 0;
          left: auto;
        }
      
      }
    }

    .tns-outer {
      margin: 0;
    }

    .content {
      margin: 0;
      width: 100%;
      max-width: 100%;
      padding: 32px 0;

      @include breakpoint(xlarge) {
        padding: 32px 0 64px 0;
      }

      h2 {
        margin-top: 15px;
      }

      h2 + p {
        margin-top: 23px;
      }
    }
  }

  .services + .customteaser .split {
    margin-top: 0;
  }
}